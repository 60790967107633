import '@/assets/iconfont.css';
import { createApp } from 'vue';
import pinia from '/@/stores/index';
import App from '/@/App.vue';
import router from '/@/router';
import { directive } from '/@/directive/index';
import { i18n } from '/@/i18n/index';
import other from '/@/utils/other';
import 'amfe-flexible'; // 引入 lib-flexible
import '@/common/flexible.js';
import ElementPlus from 'element-plus';
import '/@/theme/index.scss';
import VueGridLayout from 'vue-grid-layout';
import { createMetaManager } from 'vue-meta';
import { createHead } from '@unhead/vue';
const app = createApp(App);
const head = createHead();
app.use(head);
const metaManager = createMetaManager();
app.use(metaManager);
directive(app);
other.elSvg(app);

app.use(pinia).use(router).use(ElementPlus).use(i18n).use(VueGridLayout).mount('#app');
