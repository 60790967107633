// src/common/useMediaQuery.js
import { ref, onMounted, onUnmounted } from 'vue';

export function useMediaQuery(query) {
    const matches = ref(window.matchMedia(query).matches);

    function updateMatches(event) {
        matches.value = event.matches;
    }

    onMounted(() => {
        const mediaQueryList = window.matchMedia(query);
        updateMatches(mediaQueryList); // Initialize state
        mediaQueryList.addEventListener('change', updateMatches);
    });

    onUnmounted(() => {
        const mediaQueryList = window.matchMedia(query);
        mediaQueryList.removeEventListener('change', updateMatches);
    });

    return matches;
}
