import { createRouter, createWebHashHistory } from 'vue-router';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import pinia from '/@/stores/index';
import { storeToRefs } from 'pinia';
import { useKeepALiveNames } from '/@/stores/keepAliveNames';
import { useRoutesList } from '/@/stores/routesList';
import { useThemeConfig } from '/@/stores/themeConfig';
import { Local, Session } from '/@/utils/storage';
import { staticRoutes, notFoundAndNoPower } from '/@/router/route';
import { initFrontEndControlRoutes } from '/@/router/frontEnd';
import { initBackEndControlRoutes } from '/@/router/backEnd';
import { useMediaQuery } from '@/common/useMediaQuery.js';
const isMobile = useMediaQuery('(max-width: 1024px)');
console.log('🚀 ~ isMobile:', isMobile);
// 动态导入 views 文件夹下所有的 .vue 文件
const modules = import.meta.glob('../views/**/*.vue');

// 动态生成路由配置
const dynamicRoutes = Object.keys(modules).map((path) => {
	const name = path.match(/\/views\/(.*)\.vue$/)?.[1];
	return {
		path: `/${name?.toLowerCase()}`, // 保持原始路径
		name, // 路由名称
		component: () => modules[path](), // 动态加载组件时使用异步导入
	};
});

/**
 * 创建一个可以被 Vue 应用程序使用的路由实例
 */
export const router = createRouter({
	history: createWebHashHistory(),
	routes: [
		...notFoundAndNoPower,
		...staticRoutes,
		...dynamicRoutes,
		...[
			{
				path: '/', // 首页路径
				component: () => import('@/views/index.vue'), // 显示 index.vue
			},
			{
				path: '/index',
				redirect: '/', // 重定向 /index 到 /
			},
		],
	],
});

// 路由加载前
router.beforeEach(async (to, from, next) => {
	NProgress.configure({ showSpinner: true });
	NProgress.start();
	const token = localStorage.getItem('token1');
	console.log('🚀 ~ router.beforeEach ~ token:', token);
	if (!token) {
		next();
		NProgress.done();
	} else if (token && to.path === '/index') {
		next('/');
		NProgress.done();
	} else if (token && to.path === '/mobile/login') {
		next('/mobile/home');
		NProgress.done();
	} else {
		console.log(444444);
		next();
	}
});

// 路由加载后
router.afterEach(() => {
	NProgress.done();
});

// 导出路由
export default router;
